var Surveys = {
	Init: function ()
	{
		if ($('.surveys').length)
		{
			if (getStorage('surveys_voted') != null)
			{
				var surveyid = $('.survey-form form').data('surveyid');
				var voted = getStorage('surveys_voted').split(";");

				if ($.inArray(surveyid, voted))
				{
					$('.survey-widget.survey-form').hide();
					$('.survey-widget.survey-results').show();
				}
			}

			if($('.surveys .progress').length)
			{
				$('.surveys .progress').each(function(){
					var $meter = $(this).find('.meter');
					var result = $meter.attr('data-result')+"%";
					var time_animate = Math.floor((Math.random() * 30) + 3) / 10+'s';
					$meter.css({
						"transition-duration" : time_animate,
						"-webkit-transition-duration" : time_animate,
						"width" : result
					});

				});
			}
			this.Posting();
		}
	},

	Posting: function ()
	{
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		$('.survey-widget.survey-form form').on('submit', function (e)
		{
			e.preventDefault();

			var answers = [];
			$(".survey-options :checked").each(function ()
			{
				answers.push($(this).val());
			});

			var surveyid = $(this).data('surveyid');

			$.post($(this).data('url'), answers, function (res)
			{
				var voted = getStorage('surveys_voted');
				setStorage('surveys_voted', voted + ';' + surveyid);

				$('.survey-widget.survey-form').hide();
				$('.survey-widget.survey-results').show();
				$('.survey-widget.survey-messages').show();

				$('.survey-widget.survey-messages').html(res);
			});
		});
	}
};