var CarFilters = {
    blockSelector: '.js-car-filters',
    block: null,
    togglerSelector: '.js-toggle-filters',
    toggler: null,
    animationDuration: 500,

    init: function() {
        this.block = $(this.blockSelector);
        
        if(this.block.length > 0) {
            this.toggler = $(this.togglerSelector);
            this.toggler.click(this.toggleFilters);
            this.onResize();
        }
    },

    toggleFilters: function() {
        if(!CarFilters.block.hasClass('active')) {
            CarFilters.showFilters();
        }   
        else {
            CarFilters.hideFilters();
        }
    },

    showFilters: function() {
        var _this = this;
        $('body').addClass('disable-scroll');
        this.toggler.addClass('active');
        this.setTogglerLabel(0);

        this.block.animate({
            top: '0px',
            marginTop: $('.dark-top-section header').innerHeight() + 'px'
        }, this.animationDuration, function() {
            _this.block.addClass('active');
        });
    },

    hideFilters: function(duration) {
        var timeout = this.animationDuration;

        if(typeof duration !== 'undefined') {
            timeout = duration;
        }

        var _this = this;
        this.toggler.removeClass('active');
        this.setTogglerLabel(1);

        this.block.animate({
            top: (parseInt($(window).innerHeight()) - parseInt(_this.toggler.innerHeight())) + 'px',
            marginTop: '0px'
        }, timeout, function() {
            $('body').removeClass('disable-scroll');
            _this.block.removeClass('active').removeAttr('style');
        });
    },

    setTogglerLabel: function(type) {
        if(type === 0) {
            this.toggler.text(Constants.Translations.hideFilters);
        }
        else if(type === 1) {
            this.toggler.text(Constants.Translations.showFilters);
        }
    },

    onResize: function() {
        var _this = this;
        var timeout = null;

        $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
            if((oldSize === 'small' || oldSize === 'medium') && Foundation.MediaQuery.atLeast('large')) {
                // on resize to large breakpoint
                clearTimeout(timeout);
                _this.hideFilters(0);
            }

            if(!Foundation.MediaQuery.atLeast('large')) {
                if(_this.block.hasClass('active')) {
                    timeout = setTimeout(function() {
                        _this.block.css('margin-top', $('.dark-top-section header').innerHeight() + 'px');
                    }, 400);
                }
            }
        });
    }
}