var mzdPrevWidth = window.innerWidth;
var mzdPrevScroll = $(window).scrollTop();

$(document).ready(function () {
    $(document).foundation();

    Menu.init();

    Surveys.Init();

    checkboxStyled();

    anotherOption();

    responsiveTables();

    Login.Init();

    Rotator.initMainRotator();

    ItemsWidget.init();

    CarFilters.init();

    Datepicker.init();
    //=============================================//
    //--------------- LIGHTBOX INIT ---------------//
    //=============================================//
    // var selectorF = "a[rel*=lightbox]";
    // var instanceF = $(selectorF).imagelightbox({
    //     onStart: function () {
    //         overlayOn();
    //         closeButtonOn(instanceF);
    //         arrowsOn(instanceF, selectorF);
    //     },
    //     onEnd: function () {
    //         overlayOff();
    //         captionOff();
    //         closeButtonOff();
    //         arrowsOff();
    //         activityIndicatorOff();
    //     },
    //     onLoadStart: function () {
    //         captionOff();
    //         activityIndicatorOn();
    //     },
    //     onLoadEnd: function () {
    //         captionOn();
    //         activityIndicatorOff();
    //         $('.imagelightbox-arrow').css('display', 'block');
    //     }
    // });
    //=============================================//

    // $('.foundation-mq').attr('content', '').attr('property', '')
});

$(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
    if ((oldSize === 'small' || oldSize === 'medium') && Foundation.MediaQuery.atLeast('large')) {
        // on resize to large breakpoint
        Menu.setDefaultMainMenu();
        Menu.setDefaulBottomMenu();
    } else if ((oldSize !== 'small' && oldSize !== 'medium') && !Foundation.MediaQuery.atLeast('large')) {
        // on resize to medium breakpoint

    }

    if (oldSize === 'small' && Foundation.MediaQuery.atLeast('medium')) {
        // on resize to medium breakpoint
        OffersSearchWidget.setDefaultBlock();
    } else if (oldSize !== 'small' && !Foundation.MediaQuery.atLeast('medium')) {
        // on resize to small breakpoint

    }
});