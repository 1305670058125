function getCookie(name) {
    var dc = document.cookie;
    var cname = name + "=";
    var clen = dc.length;
    var cbegin = 0;

    while (cbegin < clen) {
        var vbegin = cbegin + cname.length;

        if (dc.substring(cbegin, vbegin) == cname) {
            var vend = dc.indexOf(";", vbegin);
            if (vend == -1) vend = clen;

            return unescape(dc.substring(vbegin, vend));
        }

        cbegin = dc.indexOf(" ", cbegin) + 1;

        if (cbegin == 0) break;
    }
    return null;
}

function setCookie(name, value, days, path, domain, secure) {
    var expires = null;

    if (days) {
        expires = new Date();
        var theDay = expires.getDate();
        theDay = theDay + days;
        expires.setDate(theDay);
    }

    var ciacho = name + "=" + escape(value) +
        ((expires) ? "; expires=" + expires.toGMTString() : "") +
        ((path) ? "; path=" + path : "") +
        ((domain) ? "; domain=" + domain : "") +
        ((secure) ? "; secure" : "");

    document.cookie = ciacho;
}

var setStorage = function (key, content, expires) {
    if (!expires) expires = 24 * 3600;

    var date = new Date();
    var schedule = Math.round((date.setSeconds(date.getSeconds() + expires)) / 1000);

    localStorage.setItem(key, content);
    localStorage.setItem(key + '.expires', schedule);
};

var getStorage = function (key) {
    var date = new Date();
    var current = Math.round(date / 1000);
    var expires = localStorage.getItem(key + '.expires');

    if (!expires) expires = 0;

    if (expires < current) {
        localStorage.removeItem(key);
        localStorage.removeItem(key + '.expires');
    }

    return localStorage.getItem(key);
};

var checkboxStyled = function () {
    $('input[type=checkbox], input[type=radio]').each(function () {
        var id = $(this).attr('id');

        if (typeof id === 'undefined') {
            var name = $(this).attr('name');
            var value = $(this).attr('value') || '';

            id = name + '_' + value;

            $(this).attr('id', id);
        }

        if (typeof id !== 'undefined') {
            $(this).closest('label').attr('for', id);
        }
        
				// if ($(this).attr('checked') ) {
        //     $(this).parents('label').find('.checkbox-yes').show();
        //     $(this).parents('label').find('.checkbox-no').hide();
        // } else {
        //     $(this).parents('label').find('.checkbox-no').show();
        //     $(this).parents('label').find('.checkbox-yes').hide();
        // }

        $(this).after('<span></span>');
    });
}

var anotherOption = function () {
    if ($('.form-radios-alt').length) {
        $('.form-radios input[type=radio]').each(function () {
            if ($(this).val() === '-1' && $(this).is(":checked")) {
                $(this).closest('.form-radios').next('.form-radios-alt').addClass('open');
            }
        });
    }
    $('.form-radios input[type=radio]').change(function () {
        var $radios_alt = $(this).closest('.form-radios').next('.form-radios-alt');
        if ($(this).val() === '-1') {
            $radios_alt.addClass('open');
        } else {
            $radios_alt.removeClass('open');
        }
    });
}

var responsiveTables = function () {
    $('table').each(function (index, item) {
        var item = $(item);

        if (!item.parent().hasClass('.table-scroll')) {
            item.wrap('<div class="table-scroll"></div>');
        }
    });
}

// zwraca szerokość scrollbara w danej przeglądarce
function getScrollBarWidth() {
    var $outer = $('<div>').css({
            visibility: 'hidden',
            width: 100,
            overflow: 'scroll'
        }).appendTo('body'),
        widthWithScroll = $('<div>').css({
            width: '100%'
        }).appendTo($outer).outerWidth();
    $outer.remove();
    return 100 - widthWithScroll;
}

var getScreenWidth = function () {
    return $(window).width() + getScrollBarWidth();
}
var mobile = (getScreenWidth() <= 767) ? 1 : 0;
var tablet = (getScreenWidth() <= 1024) ? 1 : 0;

var Loader = {
    loader_obj: '',
    Init: function () {
        this.loader_obj = $('<div class="edito-overlay">');
    },
    Add: function (overlay, handler) {
        this.Init();
        overlay = (typeof overlay === 'undefined') ? 1 : overlay;
        handler = (typeof handler === 'undefined') ? 0 : handler;

        this.loader_obj.append('<div class="loader"><div class="la-ball-spin-fade-rotating"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>');

        if (overlay != 1 && overlay.length > 0) {
            this.loader_obj.addClass(overlay);
        }

        if (handler) {
            handler.append(this.loader_obj);
        } else {
            $('body').prepend(this.loader_obj);
        }
    },
    Destroy: function () {
        $('.edito-overlay').remove();
    }
}

function createRotator(selector, options) {
    var def_options = {
        effect: 'slide',
        speed: 1000,
        paginationClickable: true,
    };
    var timeout = null;
    var pagination = null;
    var buttonNext = null;
    var buttonPrev = null;

    if (typeof (timeout = $(selector).attr('data-timeout')) !== 'undefined' && timeout > 0) {
        def_options.autoplay = timeout;
    }
    if (typeof (pagination = $(selector).find('.swiper-pagination')) !== 'undefined') {
        def_options.pagination = pagination;
    }
    if (typeof (buttonPrev = $(selector).find('.swiper-button-prev')) !== 'undefined') {
        def_options.prevButton = buttonPrev;
    }
    if (typeof (buttonNext = $(selector).find('.swiper-button-next')) !== 'undefined') {
        def_options.nextButton = buttonNext;
    }

    if (typeof options !== 'undefined') {
        $.extend(def_options, options);
    }

    return new Swiper(selector, def_options);
}

var ItemsWidget = {
    timeout: 300,
    widget: null,
    activeIndex: null,

    init: function () {
        var _this = this;
        this.widget = '.items-widget';

        if ($(this.widget).length > 0) {
            var _this = this;
            this.initRotator();

            setTimeout(function () {
                $('.js-toggle-desc', _this.widget).on('click', {
                    obj: _this
                }, _this.toggleDesc);
            }, 20);

            $('.js-toggle-desc').not('.close').on('mouseenter', function () {
                $(this).closest('.item').addClass('hover');
            });

            $('.js-toggle-desc').on('mouseleave', function () {
                $(this).closest('.item').removeClass('hover');
            });
        }
    },

    toggleDesc: function (e) {
        var _this = e.data.obj;
        var index = $(this).closest('.swiper-slide').attr('data-swiper-slide-index');

        if (index !== _this.index) {
            _this.showDesc(index);
        } else {
            _this.hideDesc(index);
        }
    },

    showDesc: function (index) {
        var slide = $('.swiper-slide[data-swiper-slide-index=' + index + ']', this.widget);
        var desc = slide.find('.item-desc');
        var item = slide.find('.item');
        var height = desc.innerHeight();

        item.attr('data-height', item.innerHeight());
        this.index = index;

        item.delay(400).animate({
            height: height + 'px'
        }, this.timeout);

        $('.items-rotator', this.widget).addClass('expanded');

        desc.fadeIn(this.timeout, function () {
            $('.items-rotator', this.widget)[0].swiper.lockSwipes();
        });
    },

    hideDesc: function (index) {
        var slide = $('.swiper-slide[data-swiper-slide-index=' + index + ']', this.widget);
        var desc = slide.find('.item-desc');
        var item = slide.find('.item');
        var height = parseInt(item.attr('data-height'));
        var _this = this;

        item.animate({
            height: height + 'px'
        }, this.timeout, function () {
            $(this).removeAttr('style');
        });

        $('.items-rotator', this.widget).removeClass('expanded');

        desc.fadeOut(this.timeout, function () {
            $(this).removeAttr('style').hide();
            _this.index = null;

            $('.items-rotator', _this.widget)[0].swiper.unlockSwipes();
        });
    },

    initRotator: function () {
        var _this = this;

        createRotator($('.items-rotator', this.widget), {
            loop: true,
            slidesPerView: 4,
            slideToClickedSlide: true,
            breakpoints: {
                500: {
                    slidesPerView: 1
                },
                600: {
                    slidesPerView: 2
                },
                800: {
                    slidesPerView: 3
                },
                4096: {
                    slidesPerView: 4
                }
            },
            onTransitionStart: function (swiper) {
                $('.items-rotator', this.widget)[0].swiper.disableTouchControl();

                setTimeout(function () {
                    $('.js-toggle-desc', _this.widget).off('click', _this.toggleDesc);
                }, 1);
            },
            onTransitionEnd: function (swiper) {
                $('.items-rotator', this.widget)[0].swiper.enableTouchControl();

                $('.js-toggle-desc', _this.widget).on('click', {
                    obj: _this
                }, _this.toggleDesc);
            }
        });
    }
}

function disableScroll() {
    $('body').addClass('disable-scroll');
}

function enableScroll() {
    $('body').removeClass('disable-scroll');
}

function lockScroll(event) {
    $(window).scrollTop(event.data.top);
}

var Datepicker = {
    init: function () {
        var _this = this;

        $('input[type=date], input[type=datetime]').each(function (index, item) {
            $(this).datetimepicker($(this).data('config'));
            $(this).attr('type', 'text');

            var parent = $(item).parent('.small-10');
            parent.removeClass('small-10').addClass('small-12');
            parent.next('.small-2').remove();
        });

        $('input[data-toggle="datetimerange"]').each(function (index, item) {
            var $this = $(this);
            var value = $this.val();

            $this.daterangepicker($(this).data('config'))
                .val(value)
                .on('cancel.daterangepicker', function (ev, picker) {
                    $(this).val('');
                });

            $this.attr('type', 'text');

            var parent = $(item).parent('.small-10');
            parent.removeClass('small-10').addClass('small-12');
            parent.next('.small-2').remove();
        });
    },

    getConfig: function (item) {
        var config = $(item).attr('data-config');

        return JSON.parse(config);
    }
}


var tinymceInitializer = {
    options: {
        selector: 'textarea[data-editor]',
        plugins: [
            'autolink lists link image charmap hr anchor autoresize',
            'searchreplace wordcount visualblocks visualchars code',
            'insertdatetime media nonbreaking table contextmenu directionality',
            'paste textcolor colorpicker textpattern',
            'localautosave floatingtoolbar linktools tabletools'
        ],
        toolbar1: [
            'undo', 'redo', '|', 'bold', 'italic', '|', 'alignleft', 'aligncenter', 'alignright', 'alignjustify', '|',
            'bullist', 'numlist', 'outdent', 'indent', '|', 'tabletools_insert', 'link', '|',
            'forecolor', 'backcolor', '|', 'code', 'localautosave'
        ].join(' '),

        relative_urls: false,
        paste_data_images: false,
        browser_spellcheck: true,
        entity_encoding: "raw",
        autoresize_bottom_margin: 50,
        nowrap: false,
        resize: false,
        language: $('html').attr('lang'),
        floatingtoolbar_top: 51,
        linktools_toolbar: 'link | unlink',
        las_seconds: 15,
        table_style_by_css: true,

        extended_valid_elements: "div[class|data*]",

        setup: function (editor) {
            editor.on('BeforeSetContent', function (e) {
                if (e.content) {
                    var html = $('<div></div>').html(e.content);
                    e.content = html.html();
                }
            });
            editor.on('PostProcess', function (e) {
                if (e.get) {
                    // Zablokowanie doczytywania obrazkow przez parser jquery
                    e.content = e.content.replace(new RegExp('src', 'g'), 'src-disabled');

                    var html = $('<div></div>').html(e.content);

                    e.content = html.html();

                    // Przywrocenie oryginalnego kodu html
                    e.content = e.content.replace(new RegExp('src-disabled', 'g'), 'src');
                }
            });
        }
    },

    init: function () {
        if (!window.tinymce) return;

        if (typeof (tinymceOnInit) === 'function') {
            tinymceOnInit(this.options);
        }

        tinymce.init(this.options);
    }
};

/* TinyMCE */
$(function () {
    tinymceInitializer.init();
});