var OffersWidget = {

    container: null,
    init: function(selector, url, maxitems)
    {
        this.container = $(selector);
        $this = this;

        this.container.on('tabchanged', function(event){
            var container = $(this);
            Loader.Add('transparent', container);
            $.ajax({
                type: "POST",
                url: url,
                data: {idMark: event.idMark, active: event.active? 1 : 0, maxitems: maxitems},
                dataType: 'json',
                success: function( response ){
                    $('.jq-list', container).html(response.view);
                    $('a.jq-link', container).attr('href', response.link);
                    Loader.Destroy();
                    Foundation.reInit('equalizer');
                },
                error: function(){
                    Loader.Destroy();
                }
            });
            
        });
    }
};