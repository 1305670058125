Menu = {
	$menuToggle: '.js-toggle-menu',
	$menuBottomToggle: '.js-toggle-bottom-menu',
	$mainMenu: '#main-menu',
	$mainMenuNav: '.main-menu-nav',
	slideTimeout: 200,
	headerScrollBreakpoint: 10,

	init: function() {
		$(this.$menuToggle).click(this.toggleMainMenu);
		$(this.$menuBottomToggle).click(this.toggleBottomMenu);

		this.initFixedHeader();
	},

	toggleMainMenu: function() {
		var _this = $(this);

		if(!_this.hasClass('active')) {
			_this.addClass('active');
			Menu.openMainMenu();
		}
		else {
			_this.removeClass('active');
			Menu.closeMainMenu();
		}
	},

	openMainMenu: function() {
		$(this.$mainMenuNav).slideDown(this.slideTimeout).addClass('active');
		disableScroll();
	},

	closeMainMenu: function() {
		$(this.$mainMenuNav).slideUp(this.slideTimeout).removeClass('active');
		enableScroll();
	},

	toggleBottomMenu: function() {
		var _this = $(this);

        if(!Foundation.MediaQuery.atLeast('large')) {
            if(!_this.hasClass('active')) {
                Menu.openBottomMenu(_this);
            }
            else {
                Menu.closeBottomMenu(_this);
            }
        }
	},

	openBottomMenu: function(item) {
		item.addClass('active');
        item.closest('.widget').find('.js-menu-block').slideDown(this.slideTimeout).addClass('active');

        item.closest('.columns').nextAll('.columns').each(function(index, value) {
            if($(value).find('.widget .heading .spacer').length > 0) {
                $(value).find('.js-menu-block').slideDown(this.slideTimeout).addClass('active');
            }
            else {
                return false;
            }
        });
	},

	closeBottomMenu: function(item) {
        item.removeClass('active');
		item.closest('.widget').find('.js-menu-block').slideUp(this.slideTimeout).removeClass('active');

        item.closest('.columns').nextAll('.columns').each(function(index, value) {
            if($(value).find('.widget .heading .spacer').length > 0) {
                $(value).find('.js-menu-block').slideUp(this.slideTimeout).addClass('active');
            }
            else {
                return false;
            }
        });
	},

	setDefaultMainMenu: function() {
		enableScroll();
		$(this.$mainMenuNav).removeClass('active').removeAttr('style');
		$(this.$menuToggle).removeClass('active');
	},

	setDefaulBottomMenu: function() {
		$('.js-toggle-bottom-menu.active').removeClass('active');
		$('.js-menu-block').removeClass('active').removeAttr('style');
	},

	initFixedHeader: function() {
		var _this = this;

		$(window).scroll(function() {
			var scrollTop = $(window).scrollTop();

			if(mzdPrevScroll <= _this.headerScrollBreakpoint && scrollTop > _this.headerScrollBreakpoint) {
				$('body').addClass('scroll-header');
			}
			else if(mzdPrevScroll > _this.headerScrollBreakpoint && scrollTop <= _this.headerScrollBreakpoint) {
				$('body').removeClass('scroll-header');
			}

			mzdPrevScroll = $(window).scrollTop();
		});
	},

	open: function($handle) {
		$closest = $handle.attr('data-closest');
		$handle.toggleClass('active').closest($closest).children('ul').slideToggle();
	}
};