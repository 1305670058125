var Rotator = {
    initMainRotator: function() {
        var selector = '.main-rotator';
        var block = $(selector);
        var _this = this;

        if(block.length > 0) {
            if(Foundation.MediaQuery.atLeast('medium')) {
                // init if not mobile
                _this.createMainRotator(selector);
            }
            else {
                // else init if breakpoint change from mobile
                $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
                    if(oldSize === 'small' && Foundation.MediaQuery.atLeast('medium') && typeof $(selector)[0].swiper === 'undefined') {
                        _this.createMainRotator(selector);
                    }
                });
            }
        }
    },
    createMainRotator: function(selector) {
        createRotator(selector, {
            autoplay: 4000,
            loop: true
        });
    }
};