var CookieInfo = {
    parent: 'body',
    type: 1,
    infoContent: $('<div class="cookieInfo"><div class="row"><div class="columns"><div class="info"><div class="text">' + _t.CookieInfo.text + '</div></div><div class="buttons"><button class="button primary blue">' + _t.CookieInfo.button + '</button></div></div></div></div>'),
    
    Init: function () {
        var cookie = this.GetCookie();

        if (cookie == undefined || cookie == null) {
            this.addContent();
            this.onClick();
        }
    },

    addContent: function () {
        $(this.parent).append(this.infoContent);
    },

    onClick: function () {
        $(this.infoContent).find('button').on('click', function () {
           CookieInfo.SetCookie();
           CookieInfo.infoContent.fadeOut(300, function() {
               $(this).remove();
           });
        });
    },

    SetCookie: function () {
        setCookie('cookieInfo', this.type, 100, '/');
    },

    GetCookie: function () {
        return getCookie('cookieInfo');
    },
}